<template>
  <v-app>
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-header">
            <div class="card-title pt-3 px-3 d-flex justify-content-between">
              <div class="breadcrumb-left">
                <h4>My activities</h4>
                <div class="breadcrumb-sub-header">
                  <router-link to="/dashboard">Dashboard</router-link>
                  \ My activities
                </div>
              </div>

            </div>
          </div>
          <div class="card-body">
            <div>
              <v-skeleton-loader v-bind="attrs" v-if="loading"
                               type="card-heading,list-item-three-line">
              </v-skeleton-loader>
            </div>
            <div class="timeline timeline-6 mt-3" v-if="!loading">
              <div class="timeline-item align-items-start" v-show="timelines.length > 0" v-for="(timeline,index) in  timelines" :key="index">
                <!--begin::Label-->
                <div class="timeline-label font-weight-bolder text-dark-75 font-size-lg">{{timeline.student_time_stamp}}</div>
                <!--end::Label-->

                <!--begin::Badge-->
                <div class="timeline-badge" v-bind:class="timeline.badge">
                  <i class="fa fa-genderless icon-xl" v-bind:class="timeline.text_color"></i>
                </div>
                <!--end::Badge-->

                <!--begin::Text-->
                <div class="font-weight-medium font-size-lg timeline-content  pl-5">
                  {{timeline.title}}
                </div>
                <!--end::Text-->
              </div>
            </div>

          </div>
        </div>
      </div>
    </div>
  </v-app>
</template>
<script>
import DashboardService from "@/core/services/dashboard/DashboardService";
const dashboardService = new DashboardService();
export default {
  data(){
    return{
      timelines:[],
      loading:false,
      attrs: {
        class: 'mb-6',
        boilerplate: true,
        elevation: 2,
      },
    }
  },
  methods:{
    getTimelineStats() {
      this.loading=true;
      dashboardService
          .getTimelineStats()
          .then(response => {
              this.timelines = response.data.contents;
          })
          .catch(error => {

          })
          .finally(()=>{
            this.loading=false;
          });
    },
  },
  mounted() {
    this.getTimelineStats();
  }
}
</script>